/* latin-ext */
@font-face {
  font-family: 'Grand Hotel';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/grandhotel/v13/7Au7p_IgjDKdCRWuR1azplQEGFws0VEwyew.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Grand Hotel';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/grandhotel/v13/7Au7p_IgjDKdCRWuR1azplQKGFws0VEw.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

body {
  color: #2f3f5a
}

.headerRootWrap {
  /*height: 64px; */
}

.headerRoot {
  flex-grow: 1; 
  background-color: white;
  transition: top 0.6s;
}

.headerWrapHomePage .headerRoot { 
  background-color: transparent; 
}

.headerRootFixed {
  transition: top 0.6s;
}

.headerRootFixed:after {
  left: 0;
  bottom: -5px;
  background-position: left 0 top -2px;
  position: absolute;
  width: 100%;
  height: 5px;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAHBAMAAADzDtBxAAAAD1BMVEUAAAAAAAAAAAAAAAAAAABPDueNAAAABXRSTlMUCS0gBIh/TXEAAAAaSURBVAjXYxCEAgY4UIICBmMogMsgFLtAAQCNSwXZKOdPxgAAAABJRU5ErkJggg==');
  background-repeat: repeat-x;
  content: "";
}

.headermenuButton {
  margin-right: - theme.spacing(2);
}

.headerTitle {
  flex-grow: 1;
  font-size: 25px;
  text-rendering: optimizeLegibility;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  cursor: pointer;
}

.headerTitleA {
	color: #3880ff;
	font-weight: 500;
	text-decoration: none;
}

.headerTitleA:hover, .headerTitleA:active {
	text-decoration: none !important;
}

.footerXs {
  background-color: #F7F7F7 !important;
  border-top: 1px solid #DDDDDD !important;  
  padding-left: 4px !important;
  padding-right: 4px !important;
} 

.footerSm {
  background-color: #F7F7F7 !important;
  border-top: 1px solid #DDDDDD !important;  
  padding-left: 24px !important;
  padding-right: 24px !important;
}

.footerBottomMenuSm {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
  display: flex;
  flex-direction: row;
  max-width: 960px;
  margin: auto;
  padding-left: 12px;
  padding-right: 12px
}

.footerBottomMenuXs {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
  display: flex;
  flex-direction: column
}

.footerMenuSection {
  flex: 1 0 0% !important;
  padding-left: 12px !important;
  padding-right: 12px !important
}

.footerMenuSectionTitle {
  font-size: 12px;
  color: #2f3f5a;
  font-weight: 600
}

.footerMenuUl {
	display: block !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
  padding: 0;
  padding-left: 0;
  list-style: none
}

.footerMenuItem {
	margin-top: 16px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  font-size: 14px !important;
  line-height: 18px !important; 
}

.footerMenuLink {
  color: #2f3f5a !important;
  text-decoration: none; 
}

.footerMenuLink:hover {
  text-decoration: underline;
}

.footer__copyright {
	padding: 0 0 18px 0;
	color: #2f3f5a;
}

.footer__social {
  padding: 0 0 25px 0; 
  display: flex; 
  opacity: 0.54;
  justify-content: center;
}

.content {
  padding-top: 64px;
}

@media (max-width: 599px) {
  .content {
    padding-top: 48px;
  }
}

* {
  box-sizing: border-box;
}

input,
button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  border-style: none;
} 

body {
  height: 100%;
  margin: 0;
}

#root {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
} 

@keyframes fade {
  from {
    opacity: 0;
    transform: scale3D(0.95, 0.95, 0.95);
  }
  to {
    opacity: 1;
    transform: scale3D(1, 1, 1);
  }
}

.Form {
  animation: fade 200ms ease-out;
}

.FormGroup {
  margin: 0 15px 20px;
  padding: 0;
  border-style: none;
  background-color: rgb(49 145 255);
  will-change: opacity, transform;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 #829fff;
  border-radius: 4px;
}

.FormRow {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 15px;
  border-top: 1px solid #819efc;
}

.FormRow:first-child {
  border-top: none;
}

.FormRowLabel {
  width: 15%;
  min-width: 70px;
  padding: 11px 0;
  color: #c4f0ff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@keyframes void-animation-out {
  0%,
  to {
    opacity: 1;
  }
}
.FormRowInput:-webkit-autofill {
  -webkit-text-fill-color: #fce883;
  /* Hack to hide the default webkit autofill */
  transition: background-color 100000000s;
  animation: 1ms void-animation-out;
}

.FormRowInput {
  font-size: 16px;
  width: 100%;
  padding: 11px 15px 11px 0;
  color: #fff;
  background-color: transparent;
  animation: 1ms void-animation-out;
}

.FormRowInput::placeholder {
  color: #87bbfd;
}

.StripeElement--webkit-autofill {
  background: transparent !important;
}

.StripeElement {
  width: 100%;
  padding: 11px 15px 11px 0;
}

.SubmitButton {
  display: block;
  font-size: 16px;
  width: calc(100% - 30px);
  height: 40px;
  margin: 40px 15px 0;
  background-color: rgb(80 86 107);
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 #ffb9f6;
  border-radius: 4px;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  transition: all 100ms ease-in-out;
  will-change: transform, background-color, box-shadow;
}

.SubmitButton:active {
  background-color: #d782d9;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 #e298d8;
  transform: scale(0.99);
}

.SubmitButton.SubmitButton--error {
  transform: translateY(15px);
}
.SubmitButton.SubmitButton--error:active {
  transform: scale(0.99) translateY(15px);
}

.SubmitButton:disabled {
  opacity: 0.5;
  cursor: default;
  background-color: #7795f8;
  box-shadow: none;
}

.ErrorMessage {
  color: #dc2727;
  position: relative;
  display: flex;
  justify-content: flex-start;
  padding: 0 15px;
  font-size: 13px;
  margin-top: 0px;
  width: 100%;
  transform: translateY(-15px);
  opacity: 0;
  animation: fade 150ms ease-out;
  animation-delay: 50ms;
  animation-fill-mode: forwards;
  will-change: opacity, transform;
}

.ErrorMessage svg {
  margin-right: 10px;
}

.Result {
  margin-top: 50px;
  text-align: center;
  animation: fade 200ms ease-out;
}

.ResultTitle {
  color: #fff;
  font-weight: 500;
  margin-bottom: 8px;
  font-size: 17px;
  text-align: center;
}

.ResultMessage {
  color: #9cdbff;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 25px;
  line-height: 1.6em;
  text-align: center;
}

.ResetButton {
  border: 0;
  cursor: pointer;
  background: transparent;
}

.react-tel-input .special-label { 
  top: -12px !important;
  left: 5px !important; 
  font-size: 16px !important; 
  color: rgba(0, 0, 0, 0.54) !important;
  letter-spacing: 0.00938em !important;
  transform: scale(0.75) !important;
}
